<template>
  <Stack
    :align="displayLogo ? 'center' : 'start'"
    :justify="displayLogo ? 'center' : 'start'"
    direction="col"
    :class="[
      'ui-w-full ui-animate-pulse ui-bg-neutral-light ui-rounded-sm',
      props.className,
    ]"
  >
    <SvgLogo
      v-if="props.displayLogo"
      name="mkm-grey"
      class-name="ui-w-[130px] sm:ui-w-[250px]"
    />
    <slot />
  </Stack>
</template>
<script lang="ts" setup>
import Stack from "../Layout/Stack/Stack.vue";
import SvgLogo from "../SvgLogo/SvgLogo.vue";
import { SkeletonLoaderProps } from "./skeletonLoaderTypes";

const props = withDefaults(defineProps<SkeletonLoaderProps>(), {
  displayLogo: true,
});
</script>
